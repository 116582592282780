import { DISCOUNT_TIME } from "../types";

const INITIAL_STATE = {
	time: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DISCOUNT_TIME:
			return { ...state, time: action.payload };
		default:
			return state;
	}
};
