import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { OpenRoutes, PrivateRoutes } from "./routes/Routes";
import { LocalStorage } from "./helpers/LocalStorage";
import { setUserData } from "./redux/actions/UserAction";
import './App.css';

function App() {
	const isMobile = useMediaQuery({ query: "(max-width: 428px)" });
	const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
	const isBigScreenTablet = useMediaQuery({ query: "(max-width: 1024px)" });
	const dispatch = useDispatch();

	const userData = JSON.parse(new LocalStorage().getUserData());

	let Routes = [];
	if (userData == null) {
		Routes = OpenRoutes;
	} else {
		Routes = PrivateRoutes;
		dispatch(setUserData(userData));
	}

	return (
		<Router>
			<div
				style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
			>
				<React.Suspense fallback={() => <span></span>}>
					<Switch>
						{Routes.map((route, index) => {
							return (
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									name={route.name}
									render={(props) => <route.component {...props} />}
								/>
							);
						})}
						<Redirect to={"/"} />
					</Switch>
				</React.Suspense>
			</div>
		</Router>
	);
}

export default App;
