import React from "react";
import { ROUTE_PATHS } from "./RoutePaths";

const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Login = React.lazy(() => import("../pages/Login"));
const Registration = React.lazy(() => import("../pages/Registration"));
const EmailConfirmed = React.lazy(() => import("../pages/EmailConfirmed"));
const ChangePassword = React.lazy(() => import("../pages/ChangePassword"));
const PasswordChanged = React.lazy(() => import("../pages/PasswordChanged"));
const QRCode = React.lazy(() => import("../pages/QRCode"));
const RequestPasswordReset = React.lazy(() =>
	import("../pages/RequestPasswordReset")
);
const PasswordResetLinkExpired = React.lazy(() =>
	import("../pages/PasswordResetLinkExpired")
);
const EmailConfirmationLinkExpired = React.lazy(() =>
	import("../pages/EmailConfirmationLinkExpired")
);
const ChooseName = React.lazy(() => import("../pages/ChooseName"));
const ChooseUsername = React.lazy(() => import("../pages/ChooseUsername"));
const InvitationCode = React.lazy(() => import("../pages/InvitationCode"));
const Subscribe = React.lazy(() => import("../pages/Subscribe"));
const Buy = React.lazy(() => import("../pages/Buy"));
const VerifyPhoneNumber = React.lazy(() =>
	import("../pages/VerifyPhoneNumber")
);
const TestCloudFunctions = React.lazy(() =>
	import("../pages/TestCloudFunctions")
);
const CreateFirestoreIndexes = React.lazy(() =>
	import("../pages/CreateFirestoreIndexes")
);
const TestSecurityRules = React.lazy(() =>
	import("../pages/TestSecurityRules")
);
const RecoverEmailLinkExpired = React.lazy(() =>
	import("../pages/RecoverEmailLinkExpired")
);
const EmailRecovered = React.lazy(() => import("../pages/EmailRecovered"));

const OpenRoutes = [
	{
		path: ROUTE_PATHS.login,
		exact: true,
		name: "Login",
		component: Login,
	},
	{
		path: ROUTE_PATHS.registration,
		exact: true,
		name: "Registration",
		component: Registration,
	},
	{
		path: ROUTE_PATHS.emailConfirmed,
		exact: true,
		name: "EmailConfirmed",
		component: EmailConfirmed,
	},
	{
		path: ROUTE_PATHS.changePassword,
		exact: true,
		name: "ChangePassword",
		component: ChangePassword,
	},
	{
		path: ROUTE_PATHS.passwordChanged,
		exact: true,
		name: "PasswordChanged",
		component: PasswordChanged,
	},
	{
		path: ROUTE_PATHS.qrCode,
		exact: true,
		name: "QRCode",
		component: QRCode,
	},
	{
		path: ROUTE_PATHS.requestPasswordReset,
		exact: true,
		name: "RequestPasswordReset",
		component: RequestPasswordReset,
	},
	{
		path: ROUTE_PATHS.passwordResetLinkExpired,
		exact: true,
		name: "PasswordResetLinkExpired",
		component: PasswordResetLinkExpired,
	},
	{
		path: ROUTE_PATHS.emailConfirmationLinkExpired,
		exact: true,
		name: "EmailConfirmationLinkExpired",
		component: EmailConfirmationLinkExpired,
	},
	{
		path: ROUTE_PATHS.chooseName,
		exact: true,
		name: "ChooseName",
		component: ChooseName,
	},
	{
		path: ROUTE_PATHS.chooseUsername,
		exact: true,
		name: "ChooseUsername",
		component: ChooseUsername,
	},
	{
		path: ROUTE_PATHS.invitationCode,
		exact: true,
		name: "InvitationCode",
		component: InvitationCode,
	},
	{
		path: ROUTE_PATHS.subscribe,
		exact: true,
		name: "Subscribe",
		component: Subscribe,
	},
	{
		path: ROUTE_PATHS.buy,
		exact: true,
		name: "Buy",
		component: Buy,
	},
	{
		path: ROUTE_PATHS.recoverEmailLinkExpired,
		exact: true,
		name: "RecoverEmailLinkExpired",
		component: RecoverEmailLinkExpired,
	},
	{
		path: ROUTE_PATHS.emailRecovered,
		exact: true,
		name: "EmailRecovered",
		component: EmailRecovered,
	},
];

const PrivateRoutes = [
	{
		path: ROUTE_PATHS.dashboard,
		exact: true,
		name: "Dashboard",
		component: Dashboard,
	},
	{
		path: ROUTE_PATHS.verifyPhoneNumber,
		exact: true,
		name: "VerifyPhoneNumber",
		component: VerifyPhoneNumber,
	},
	{
		path: ROUTE_PATHS.buy,
		exact: true,
		name: "Buy",
		component: Buy,
	},
	{
		path: ROUTE_PATHS.emailConfirmed,
		exact: true,
		name: "EmailConfirmed",
		component: EmailConfirmed,
	},
	{
		path: ROUTE_PATHS.testCloudFunctions,
		exact: true,
		name: "TestCloudFunctions",
		component: TestCloudFunctions,
	},
	{
		path: ROUTE_PATHS.testSecurityRules,
		exact: true,
		name: "TestSecurityRules",
		component: TestSecurityRules,
	},
	{
		path: ROUTE_PATHS.createFirestoreIndexes,
		exact: true,
		name: "CreateFirestoreIndexes",
		component: CreateFirestoreIndexes,
	},
];

export { OpenRoutes, PrivateRoutes };
