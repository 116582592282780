export const ROUTE_PATHS = {
	dashboard: "/",
	login: "/",
	registration: "/register",
	emailConfirmed: "/email-confirmed",
	changePassword: "/change-password",
	passwordChanged: "/password-changed",
	qrCode: "/qr-code",
	requestPasswordReset: "/reset-password",
	passwordResetLinkExpired: "/password-reset-link-expired",
	emailConfirmationLinkExpired: "/email-confirmation-link-expired",
	chooseName: "/name",
	chooseUsername: "/duals-username",
	invitationCode: "/invitation-code",
	subscribe: "/join-waiting-list",
	buy: "/buy",
	verifyPhoneNumber: "/verify-phone-number",
	testCloudFunctions: "/test-cloud-functions",
	createFirestoreIndexes: "/create-firestore-indexes",
	testSecurityRules: "/test-security-rules",
	recoverEmailLinkExpired: "/recover-email-link-expired",
	emailRecovered: "/email-recovered",
};
