export const firebaseConfig = {
  apiKey: "AIzaSyAMkJ-E0FkyzZa_jaDwFxjG3EQknvadnD8",
  authDomain: "my.duals.app",
  databaseURL: "https://duals-values.firebaseio.com",
  projectId: "duals-app",
  storageBucket: "public-duals",
  messagingSenderId: "760931599885",
  appId: "1:760931599885:web:ef255abc9d50e20010b744",
  measurementId: "G-SSF6BDTEN8",
};

export const appCheckSiteKey = "6LfdQzsbAAAAAFvSS6yqJmf88J6LWZlnh_edFLmg";
