const wrappers = {
	getItem(key) {
		return localStorage.getItem(key);
	},

	setItem(key, value) {
		localStorage.setItem(key, value);
	},

	removeItem(key) {
		localStorage.removeItem(key);
	},
};

export class LocalStorage {
	USER_DATA = "user_data";
	REF_CODE = "ref_code";
	USER_TEMP_DATA = "user_temp_data";
	UID = "uid";
	COUPON = "coupon";
	USERNAME = "username";
	TAKE_OFFER = "take_offer";

	setUserData(value) {
		wrappers.setItem(this.USER_DATA, value);
	}

	getUserData() {
		return wrappers.getItem(this.USER_DATA);
	}

	removeUserData() {
		wrappers.removeItem(this.USER_DATA);
	}

	setRefCode(value) {
		wrappers.setItem(this.REF_CODE, value);
	}

	getRefCode() {
		return wrappers.getItem(this.REF_CODE);
	}

	removeRefCode() {
		wrappers.removeItem(this.REF_CODE);
	}

	setUserTempData(value) {
		wrappers.setItem(this.USER_TEMP_DATA, value);
	}

	getUserTempData() {
		return wrappers.getItem(this.USER_TEMP_DATA);
	}

	removeUserTempData() {
		wrappers.removeItem(this.USER_TEMP_DATA);
	}

	setUID(value) {
		wrappers.setItem(this.UID, value);
	}

	getUID() {
		return wrappers.getItem(this.UID);
	}

	removeUID() {
		wrappers.removeItem(this.UID);
	}

	setCoupon(value) {
		wrappers.setItem(this.COUPON, value);
	}

	getCoupon() {
		return wrappers.getItem(this.COUPON);
	}

	removeCoupon() {
		wrappers.removeItem(this.COUPON);
	}

	setTakeOffer(value) {
		wrappers.setItem(this.TAKE_OFFER, value);
	}

	getTakeOffer() {
		return wrappers.getItem(this.TAKE_OFFER);
	}

	removeTakeOffer() {
		wrappers.removeItem(this.TAKE_OFFER);
	}
}
